@import '@trimble-oss/modus-icons/dist/transportation/fonts/modus-icons.css';
@import 'node_modules/@sefaira/lib-core-ng/scss/index';

@import 'leaflet';
@import 'switch';
@import 'decorations';

html {
  font-size: 12px !important;
}

body {
  font-size: 1rem !important;
}

label {
  font-size: 1rem !important;
}

@import 'mixins';

@include switch-element(1.4rem, 0.4rem, 2.8rem);

.border {
  border-collapse: collapse !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 0.2rem !important;
}

.border-3 {
  border-width: 0.4rem !important;
}

.border-4 {
  border-width: 0.6rem !important;
}

.border-5 {
  border-width: 0.8rem !important;
}

input.custom-range::-webkit-slider-thumb {
  width: 1.4rem !important;
  height: 1.4rem !important;
  margin-top: -0.45rem !important;
}

.width-auto {
  width: auto !important;
}

.invisible {
  display: none;
}

.leaflet-control-scale {
  left: 8rem;
  bottom: 2rem; // align with the centre of the help icon
}

.pointer-events-show {
  pointer-events: auto;
}

.button-switch .switch:before {
  left: 0 !important;
  top: 0 !important;
}

.button-switch .switch:after {
  margin-top: 0.2rem;
  margin-left: 0.4rem;
  //transform: translate(1.55rem, 0.15rem);
}

.button-switch input.switch:checked:after {
  margin-left: 0.2rem;
  //transform: translate(1.55rem, 0.15rem) !important;
}

.ui-layers > .align-middle {
  top: 50%;
  transform: translate(0, -50%);
}

.ui-panel {
  @extend .d-block;
  @extend .fill-x;
  @extend .position-relative;
  @extend .ui-column;
  @extend .s-3;
  @extend .p-3;
  /* Not using bg-white because that has !important and we want to override this default in the templates sometimes */
  background-color: $col_white;
}

.ui-title {
  @extend .d-block;
  @extend .h1;
}

.ui-subtitle {
  @extend .d-block;
  @extend .h2;
}

.ui-text {
  @extend .d-block;
}

.ui-button {
  @extend .btn;
  @extend .btn-primary;
}

.ui-button-outline {
  @extend .btn;
  @extend .btn-outline-primary;
}

.ui-button-secondary {
  @extend .btn;
  @extend .btn-secondary;
}

.ui-button-light-gray {
  @extend .btn;
  @extend .btn-tertiary;
  border: none;
  background-color: $col_gray_light;
  &:hover {
    background-color: $col_gray_1;
  }
}

.ui-button-white {
  @extend .btn;
  @extend .btn-tertiary;
  border: none;
  background-color: $col_white;
  &:hover {
    background-color: $col_gray_1;
  }
}

.ui-popup-background {
  @extend .position-fixed;
  @extend .top;
  @extend .left;
  @extend .bottom;
  @extend .right;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.ui-popup {
  @extend .modal;
  @extend .d-block;
  @extend .position-relative;
  @extend .modal-content;
  margin: auto;
  width: 50rem;
  height: auto;
}

.ui-popup-title {
  @extend .d-block;
  @extend .modal-header;
  @extend .ui-title;
}

.ui-popup-body {
  @extend .d-block;
  @extend .ui-column;
  @extend .modal-body;
}

.ui-popup-buttons {
  @extend .d-block;
  @extend .align-right;
  @extend .d-flex;
  @extend .modal-footer;
}

.ui-row label {
  display: flex !important;
}

.ui-layers {
  @extend .position-relative;
  @extend .fill;
}

.ui-layers > * {
  @extend .position-absolute;
}

.ui-input-text {
  @extend .form-control;
  @extend .form-control-lg;
}

.ui-input-radio.active {
  background-color: $col_blue_light;
  &:hover {
    background-color: $col_blue_light;
  }
  input + label {
    color: $col_white;
  }
}

.ui-input-radio {
  background-color: $col_gray-1;
  &:hover {
    background-color: $col_blue_pale;
  }
  input + label {
    font-size: 1rem;
  }
}

.tooltip table {
  @extend .border;
  @extend .border-1;
  @extend .border-tertiary;
  border-collapse: collapse;
}

.tooltip thead {
  @extend .border;
  @extend .border-1;
  @extend .border-tertiary;
}

.tooltip tbody {
  @extend .border;
  @extend .border-1;
  @extend .border-tertiary;
}

.tooltip tr {
  @extend .border;
  @extend .border-1;
  @extend .border-tertiary;
}

.tooltip th {
  @extend .border;
  @extend .border-1;
  @extend .border-tertiary;
  @extend .bg-white;
  @extend .text-dark;
  @extend .px-3;
  @extend .py-2;
}

.tooltip td {
  @extend .border;
  @extend .border-1;
  @extend .border-tertiary;
  @extend .px-3;
  @extend .py-2;
}

.tooltip-inner {
  max-width: none !important;
}

.badge {
  @extend .small;
  @extend .align-middle;
  height: auto;
}

.badge-dark {
  @extend .bg-dark;
  @extend .text-white;
  @extend .px-2;
  @extend .py-1;
  @extend .rounded;
}

.close-button {
  font-size: 1.5rem;
  line-height: 1.5rem;
}
