// Trimble SASS Color variables
// V4 - 16 June 2022

@import 'node_modules/@sefaira/lib-core-ng/scss/colors';

// Because I've replaced _trimble-colors.scss from sketchup-shared-ts
// with a newer version of trimble colors some of the colors are missing
$col-trimble-blue-mid: #005f9e;
$col-trimble-blue-light: #009ad9;
$col-orange: #ff8b00;
$col-gray-half: #f9f9fb;
