// Copyright 2019 Trimble Inc. All Rights Reserved.
// Author: brandon@sketchup.com (Brandon Feltman)

// This must be lower than the z-index for .leaflet-top & .leaflet-bottom
$decorations-zindex: 999;

.decorations-container {
  pointer-events: none;
}

.decorations-map-pin {
  pointer-events: none;
  background-image: url(../images/map-pin.png);
  background-position: center calc(50% - 19px);
  background-repeat: no-repeat;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: $decorations-zindex;
}

.decorations-grabrect {
  overflow: hidden;
  position: absolute;
  z-index: $decorations-zindex;
}
