// Copyright 2019 Trimble Inc. All Rights Reserved.
// Author: brandon@sketchup.com (Brandon Feltman)

@import 'colors';
@import 'config';

// Box Shadow
@mixin box-shadow {
  -webkit-box-shadow: 0 0 1rem 0 rgba(0, 95, 158, 0.2);
  -moz-box-shadow: 0 0 1rem 0 rgba(0, 95, 158, 0.2);
  box-shadow: 0 0 1rem 0 rgba(0, 95, 158, 0.2);
}

// Button Element
@mixin button-element($background-color: #fff, $border-radius: 0.2rem, $button-height: 4.4rem, $color: #363545) {
  .button {
    background-color: $background-color;
    border: none;
    border-radius: $border-radius;
    color: $color;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    height: $button-height;
    line-height: 4.4rem;
    outline: none;

    &.primary {
      background-color: $button-primary-background-color;
      color: $button-primary-color;

      &:hover {
        background-color: $button-primary-background-color-hover;
      }

      &.disabled,
      &:disabled {
        background-color: $button-primary-background-color-disabled;
        cursor: not-allowed;
      }
    }

    &.secondary {
      background-color: $button-secondary-background-color;
      color: $button-secondary-color;

      &:hover {
        background-color: $button-secondary-background-color-hover;
        color: $button-secondary-color-hover;
      }

      &.disabled,
      &:disabled {
        background-color: $button-secondary-background-color-disabled;
        color: $button-secondary-color-disabled;
        cursor: not-allowed;
      }
    }

    &.teriary {
      background-color: $button-tertiary-background-color;
      color: $button-tertiary-color;

      &:hover {
        background-color: $button-tertiary-background-color-hover;
      }

      &.disabled,
      &:disabled {
        background-color: $button-tertiary-background-color-disabled;
        color: $button-tertiary-color-disabled;
        cursor: not-allowed;
      }
    }
  }
}

/**
 Placeholder @mixin for Sass.
 A mixin to style placeholders in HTML5 form elements.
 Includes also a .placeholder class to be used with a polyfill e.g.
 https://github.com/mathiasbynens/jquery-placeholder
 Requires Sass 3.2.

 Example usage (.scss):

   input {
     @include placeholder {
       // styles for placeholder here
     }
    }
*/

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

// Switch Element
/**
 * HTML structure for CSS:
 *
 * <div class="button-switch right">
 *   <input type="checkbox"class="switch">
 *   <label></label>
 * </div>
 */

@mixin switch-element($height, $margin, $width) {
  .button-switch {
    font-size: 1rem;
    min-height: $height;
    position: relative;
    $switch-background-color: $col-gray-8;
    $switch-primary-color: $col-trimble-blue-mid;
    $box-shadow-default: 0 0 0.4rem 0 rgba(0, 0, 0, 0.2);
    width: 100%;

    label {
      left: $width + 0.4rem;
      line-height: $height;
      position: relative;
      top: calc($margin / 2);
    }

    &.left {
      float: left;
      margin-right: $margin;
    }

    &.right {
      float: right;
      margin-left: $margin;
    }

    .switch {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 0;
      font-size: 1rem;
      left: 0;
      line-height: 0;
      outline: none;
      position: absolute;
      top: 0;
      width: 0;

      &:before,
      &:after {
        content: '';
        font-size: 1rem;
        position: absolute;
      }

      &:before {
        border-radius: calc($height / 2);
        background-color: $switch-background-color;
        height: $height;
        left: calc(4 / 16 * -1rem);
        transition: background-color $transition-default;
        width: $width;
        top: calc(4 / 16 * -1rem);
      }

      &:after {
        box-shadow: $box-shadow-default;
        border-radius: 50%;
        background: #fff;
        height: calc($width/2 - 0.2rem);
        transform: translate(-0.3rem, -0.1rem);
        transition: transform $transition-default;
        width: calc($width/2 - 0.2rem);
      }

      &:checked {
        &:after {
          transform: translate(1.3rem, -0.1rem);
        }

        &:before {
          background: $switch-primary-color;
        }
      }
    }
  }
}

// Text Input Element
@mixin text-input-element($background-color: #fff) {
  input[type='text'],
  .text-input {
    background-color: $background-color;
    border-radius: 0.2rem;
    border: none;
    color: $col-gray-3;
    font-size: 1.2rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 3.2rem;
    letter-spacing: normal;
    line-height: 1.5;
    outline: none;
    padding: 0 1.2rem;
    width: 100%;

    &.disabled,
    &:disabled {
      background-color: $col-gray-0;
    }

    &::placeholder {
      color: $col-gray-6;
    }

    &.compact {
      font-size: 1.2rem;
      height: 3rem;
      padding: 1rem;
    }

    &.error {
      border-bottom-color: $col-red;
    }
  }

  .input-group {
    font-size: 1.4rem;
  }

  .input-group-label {
    font-size: inherit;
  }

  .input-hint {
    font-size: 1rem;
  }
}
