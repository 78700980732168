// Copyright 2019 Trimble Inc. All Rights Reserved.
// Author: brandon@sketchup.com (Brandon Feltman)

// Leaflet related styles
@import '~leaflet/dist/leaflet.css';

.leaflet-areaselect {
  position: absolute;
  box-shadow: 0 0 0 10000rem rgba(0, 0, 0, 0.4);
}

.leaflet-areaselect-handle {
  position: absolute;
  background: #fff;
  border: 1px solid #666;
  -moz-box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  width: 16px; /* Modified by SketchUp */
  height: 16px; /* Modified by SketchUp */
  cursor: move;
  border-radius: 8px; /* Added by SketchUp */
  /* Added by SketchUp. Slightly higher than $decorations-zindex */
  z-index: 1000;
}

// We rely on the leaflet layers control for the baselayerchanged event,
// but do not want to show it.
.leaflet-control-layers {
  display: none;
}

.leaflet-control-attribution,
.leaflet-control-scale {
  font-size: 1rem;
}

.leaflet-container .leaflet-control-attribution {
  background: rgba(255, 255, 255, 0.85);
}
